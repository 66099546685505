<template>
  <div class="ticket">
    <HeroVideo />
    <div class="sales">
      <div class="ticket-sales text-center py-4">
        <h1 class="text-uppercase Sansrace-Regular mb-0">ticket sales</h1>
      </div>
      <div class="container-xl">
        <div class="row py-4">
          <div class="col-12 py-2 text-center">
            <h2 class="text-uppercase Sansrace-Regular">Get your tickets now!</h2>
          </div>
          <div class="col-12 py-2">
            <img
              src="@/assets/img/ticket/desktop sold out.png"
              class="img-fluid"
              alt="ticket"
            />
          </div>
          <div class="col-12 py-2">
            <p class="mb-0">Please note of the following information:</p>
            <ul>
              <li>
                <p class="mb-0">All tickets are subjected to 8% GST charges</p>
              </li>
              <li>
                <p class="mb-0">
                  All purchase tickets are strictly non-refundable
                </p>
              </li>
              <li>
                <p class="mb-0">
                  Any replacement or ticket transfer request must reach 
                  <a
                    href="mailtto:conference@chi.sg"
                    target="_blank"
                    class="text-decoration-none"
                    >conference@chi.sg</a
                  >
                  by 7 July 2023
                </p>
              </li>
              <li>
                <p class="mb-0">
                  For corporate registrations, get in touch with us at
                  <a
                    href="mailtto:conference@chi.sg"
                    target="_blank"
                    class="text-decoration-none"
                    >conference@chi.sg</a
                  >
                  to find out more
                </p>
              </li>
            </ul>

            <!-- <div class="text-center py-4">
              <a href="/register" class="register-btn  text-decoration-none">
                REGISTER NOW</a
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "ticket",
  components: {
    HeroVideo,
    Footer,
  },
  data() {
    return {
      type: "desktop",
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  overflow: hidden;
  .Sansrace-Regular{
  font-family: "Sansrace-Regular";

  }

  .und-red {
    border-bottom: 2px solid #bd1e2d;
  }
  .white-line {
    border-bottom: 2px solid #fff;
  }
  .sales {
    background: #200965;
    color: #fff;

    h2 {
      margin-bottom: 0px !important;
    }
    .ticket-sales {
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      color: #fff;

      h2 {
        margin-bottom: 0px !important;
      }
    }
    ul li {
      color: rgb(254, 65, 197);
      a {
        color: rgb(254, 65, 197);
        font-family: "Nexa-Bold";

      }
    }

    p {
      color: #fff;
      font-family: "Nexa-Light";
    }
    .register-btn {
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      padding: 8px 36px;
      border-radius: 32px;
      color: #fff;
      font-size: 26px;
      font-weight: bold;
    }
  }
}
</style>
